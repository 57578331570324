import Vue from "vue";
import { ToastPlugin, ModalPlugin , BootstrapVue , VBPopover  } from 'bootstrap-vue'
// import VueCompositionAPI from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import BCardCode from "@core/components/b-card-code";
import JsonExcel from 'vue-json-excel'
import 'jspdf-autotable'

import VueSlickCarousel from "vue-slick-carousel";
import VueQRCodeComponent from 'vue-qrcode-component'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";


import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";


import i18n from "@/libs/i18n";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import Vuex from "vuex";
import axios from "@/libs/axios";
import eventBus from "@/libs/bus";
// Global Components

import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import loading from "vuejs-loading-screen";

// Vue.use(loading, {
//     bg: 'transparent',
//     slot: `
//     <div class="px-5 py-3 bg-gray-800 rounded">
//       <h1 class="text-3xl text-white"><div class="fa-3x"><i class="fas fa-circle-notch fa-spin"></i></div> تحميل...</h1>
//     </div>
//   `
// })

import Print from "vue-print-nb";
// Global instruction

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import VueVideoPlayer from "vue-video-player";

// require videojs style
import "video.js/dist/video-js.css";

import InfiniteLoading from "vue-infinite-loading";

Vue.use(InfiniteLoading, {
  /* options */
});

import VueCookies from "vue-cookies";

Vue.use(VueCookies);
// import 'vue-video-player/src/custom-theme.css'
import { BSpinner } from "bootstrap-vue";
Vue.component("b-spinner", BSpinner);
import vSelect from "vue-select";
Vue.use(loading, {
  bg: "transparent",
  slot: `
    <div class="">
        <div>
        <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
        <h1 style='color:rgb(4 109 109) !important'> تحميل...</h1>
    </div>
`,
});

Vue.component("v-select", vSelect);

const MyPlugin = {
  install(Vue, options) {
    Vue.prototype.globalHelper = (str) => {
      str = str.replaceAll(/_/g, " ");
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    };

    // Vue.prototype.perFun = () => {
    //         const permissions = localStorage.getItem("per")
    //             // split to get in array
    //         const myArr = permissions.split(",");

    //         const perArr = []
    //         for (let inde in myArr) {

    //             // decrypt
    //             perArr.push(Vue.CryptoJS.AES.decrypt(myArr[inde], "EncryptDataMobileMasr").toString(Vue.CryptoJS.enc.Utf8))

    //         }

    //         return perArr
    //     } //Vue.gPluginFun()
  },
};

import moment from 'moment';

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('MM/DD/YYYY hh:mm');
  }
});

Vue.filter('formatDateAgo', function(value) {
  if (value) {
    moment.locale('ar');
      return moment(String(value)).fromNow();
  }
});

Vue.use(MyPlugin);

Vue.use(
  VueVideoPlayer
  /* {
  options: global default options,
  events: global videojs events
} */
);
Vue.use(VueSweetalert2);

import VueSession from "vue-session";
Vue.use(VueSession);

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;
Vue.config.performance = true;

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(BootstrapVue)
Vue.use(ModalPlugin);
Vue.use(Vuex);
// Vue.use(axios)
Vue.use(Print);
Vue.use(VueSlickCarousel);

Vue.component('BCardCode', BCardCode)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('qr-code', VueQRCodeComponent)

Vue.component('AppCollapse', AppCollapse)
Vue.component('AppCollapseItem', AppCollapseItem)
Vue.component('downloadExcel', JsonExcel)

Vue.directive('heightFade', heightFade);
Vue.directive('ripple', Ripple);
Vue.directive('b-popover', VBPopover)
import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);

window.Bus = new Vue();
window.axios = axios;
const session = Vue.prototype.$session;

store.dispatch("appConfig/setLanguage", localStorage.getItem("lang"));

var URL =
  process.env.VUE_APP_BASE_URL + "/vendor/" + store.getters["appConfig/langua"];

eventBus.$on("vType", (type) => {
  var URL =
    process.env.VUE_APP_BASE_URL +
    "/vendor/" +
    
    localStorage.getItem("lang");
  axios.defaults.baseURL = URL;

  // if (localStorage.getItem("lang") != null) {

  //   var URL =
  //     process.env.VUE_APP_BASE_URL +
  //     "/vendor/" +
  //     (type == null ? "" : type + "/") +
  //     localStorage.getItem("lang");
  //   axios.defaults.baseURL = URL;


  // } else {
  //   var URL =
  //     process.env.VUE_APP_BASE_URL +
  //     "/vendor/" +
  //     (type == null ? "" : type + "/") +
  //     "ar";
  //   axios.defaults.baseURL = URL;
  // }
});
var URL =
    process.env.VUE_APP_BASE_URL +
    "/vendor/" ;
  axios.defaults.baseURL = URL;


  
axios.defaults.baseURL = URL;
// Composition API
// Vue.use(VueCompositionAPI);

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
import { func } from "@/libs/per.js";
import { locale } from "core-js";
Vue.prototype.$func = func;

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");



import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn:  process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "seller.mobilemasr.com",  
  "seller-stg.mobilemasr.com",
  "api.mobilemasr.com/graphql",/^\//,],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");