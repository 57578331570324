// import axios from '@/libs/axios'

import Vue from 'vue'
export default {
    namespaced: true,
    state: {

        notifications:[],
        unreadnotifications: [],

        notificationsNav: [],
        unreadnotificationsNav: [],
        
        totalRows:0,
    },
    mutations: {
        getNotification(state,data){
            state.notifications = data;
        },
        getunreadNotifications(state,data){
            state.unreadnotifications = data;
        },

        notificationNav(state,data){
            state.notificationsNav = data;
        },
        getunreadNotificationsNav(state,data){
            state.unreadnotificationsNav = data;
        },

    },
    actions: {
        // get permissions for authenticated user
        getnotifications({ commit ,state },payload) {
            if (state.notifications.length == 0) {
               axios
               .get('notification/' + payload.perPage  + "/sortBy-" +  payload.sortBy + "-" +  payload.sortDirection + '?page=' + payload.currentPage )
                    .then((response) => {
                        commit('gettotalRows',response.data.data.notifications.total)
                        commit('getNotification',response.data.data.notifications)
                        commit('getunreadNotifications',response.data.data.unreadNotifications)
                    }).catch((error) => {
                    })
            }
        },
        
        getnotificationsNav({ commit ,state }) {
            if (state.notifications.length == 0) {
               axios
                    .get('notificationNav')
                    .then((response) => {
                        commit('notificationNav',response.data.data.notifications)
                        commit('getunreadNotificationsNav',response.data.data.unreadNotifications)
                    }).catch((error) => {
                    })
            }
        },


        makeRead(context,payload) {
            axios.post('notification/makeRead', {'notification_id': payload.notification_id , 'dir_show': payload.dir_show })
                .then((response) => {
                    context.state.notifications.data[payload.index].read_at = 1;
                    context.state.unreadnotifications.length = Number(context.state.unreadnotifications.length) - Number(1);
                }).catch((error) => {
                    console.log(error);
                })
        },


        
        makeReadNav(context,payload) {
            axios.post('notification/makeRead', {'notification_id': payload.notification_id , 'dir_show': payload.dir_show })
                .then((response) => {
                    context.state.notificationsNav[payload.index].read_at = 1;
                    context.state.unreadnotificationsNav.length = Number(context.state.unreadnotificationsNav.length) - Number(1);
                }).catch((error) => {
                    console.log(error);
                })
        },


    },

}


