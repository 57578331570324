import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/libs/axios'
import eventBus from "@/libs/bus"
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import router from '../router/index'
import ecommerceStoreModule from '@/views/PhoneGuide/eCommerceStoreModule'
import producteCommerceStoreModule from '@/views/Product/producteCommerceStoreModule'
import NewproducteCommerceStoreModule from '@/views/NewProduct/producteCommerceStoreModule'
import notifications from './notifications'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        notifications,
        'app-ecommerce': ecommerceStoreModule,
        'commerce-store': producteCommerceStoreModule,
        'New-commerce-store': NewproducteCommerceStoreModule,
    },
    strict: process.env.DEV,

    state: {
        //   permissions: localStorage.getItem('per') || [],
        token: localStorage.getItem('token') || '',
        authType: localStorage.getItem('type') || '',
        status: '',
        vendorData:{},
        isAuthenticatedUser:null
    },
    mutations: {
        // setpermissions(state, permissions) {
        //     state.permissions = permissions;

        // },
        authSuccess(state, token, authType) {
            state.token = token;
            state.status = 'success';
            //     state.authType = authType;
        },

        authError(state) {
            state.token = '';
            state.status = 'error';

            state.authType = '';
        },
        authLogout(state) {
            state.token = null;
            state.authType = null;
        },
        setAuthVendor(state , data) {
            state.vendorData = data;
            
        },

    },

    actions: {
        login(context, payload) {

            return new Promise((resolve, reject) => {

                axios.post('login', payload)
                    .then((response) => {

                        if (response.status === 200) {
                            const userData = response.data.data
                            let accessToken = userData.access_token
                            localStorage.setItem('lang', 'ar')
                            localStorage.setItem('token', userData.access_token)
                            context.commit('authSuccess', userData.access_token)
                            // userData
                            
                            context.commit('setAuthVendor', userData)
                   
                            const encryptedUserData = Vue.CryptoJS.AES.encrypt(JSON.stringify(userData), "EncryptDataMobileMasr").toString()
                            localStorage.setItem('userData', encryptedUserData)
             
                            

                            if (payload.remember_me == true) {
                                $cookies.set("token", userData.access_token, parseInt(userData.expires_in));
                                localStorage.setItem(
                                    "refreshToken",
                                    userData.refresh_token
                                );
                            } else {
                                $cookies.set("token", userData.access_token, 0);
                            }


                            
                            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken
                            router.push({
                                name: 'home'
                            })
                            this.state.authType = userData.adminstration
                            resolve(response);
                        } else if (response === 401) {
                            localStorage.removeItem('token');
                            context.commit('authError')
                            reject(error);
                        } else {
                            router.push({
                                path: '/ServerError'
                            })
                            localStorage.removeItem('token');
                            context.commit('authError')
                            reject(error);
                        }



                    })
                    .catch((error) => {
                        if (error === 401) {


                        }
                        localStorage.removeItem('token');
                        context.commit('authError')

                        reject(error);
                    })

            })

        },

        logout(context) {


            
            return new Promise((resolve, reject) => {

                axios.get('logout')
                    .then((response) => {

                        context.commit('authLogout')
                        localStorage.removeItem('token');
                        localStorage.removeItem('adminstration');
                        localStorage.removeItem('userData');
                        localStorage.removeItem('refreshToken');
                        $cookies.remove("token");
                        delete axios.defaults.headers.common['Authorization'];
                        router.push({
                            name: 'login'
                        })



                    })
                    .catch((error) => {
                     
                    })

            })

        },
        // get permissions for authenticated user
        getAuthenticatedVendor(context) {
            return new Promise((resolve, reject) => {
            
            axios
            .get('checkAuth')
            .then((response) => {
               if (response.status === 200) {
                    context.state.isAuthenticatedUser = true;
                    context.state.vendorData = response.data.data
                    // console.log(context.state.vendorData)
                    localStorage.setItem('userData', Vue.CryptoJS.AES.encrypt(JSON.stringify(response.data.data), "EncryptDataMobileMasr").toString())
                    
                    resolve(response);
                }
                
            })
            .catch((error) => {
                context.state.isAuthenticatedUser = false
                reject(error)
            })

            })
    },
        axiosLogout(context) {
            return new Promise((resolve, reject) => {

                context.commit('authLogout')
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
                router.push({
                    path: '/login'
                }).catch(()=>{})




            })
        },
    },
    getters: {
        checkAuthenticatedUser(context) {
            return context.isAuthenticatedUser
            
          },
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        /*    authType: state => state.authType,*/

        menus: (state, getters) => {
            if (getters.isAuthenticated) {
                return [{
                    name: "Logout",
                    route: "Logout"
                }]
            }
            return [{
                name: "login",
                route: "/login"
            }, ];
        }
    }
})