export const func = {
    getPer() {
        const permissions = this.perFun()

        const myArr = permissions.split(",");

        const perArr = []
        for (let inde in myArr) {

            perArr.push(Vue.CryptoJS.AES.decrypt(myArr[inde], "EncryptDataMobileMasr").toString(Vue.CryptoJS.enc.Utf8))

        }

        return perArr
    }
}