import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    // *===============================================---*
    // *--------- Phone Guide --------------------------------------------*
    // *===============================================---*
    {
      path: "/PhoneGuide",
      name: "PhoneGuide",
      component: () => import("@/views/PhoneGuide/PhoneGuideIndex.vue"),
      meta: {
        pageTitle: "دليل الاجهزة",
        breadcrumb: [
          {
            text: "دليل الاجهزة",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/PhoneGuideEcommerce",
      name: "PhoneGuideEcommerce",
      component: () => import("@/views/PhoneGuide/phoneGuideEcommIndex.vue"),
      meta: {
        contentRenderer: "sidebar-left-detached",
        contentClass: "ecommerce-application",
        pageTitle: "دليل الهاتف",
        breadcrumb: [
          {
            text: "بطاقات دليل الاجهزة",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/ShowPhoneGuide/:id",
      name: "ShowPhoneGuide",
      props: true,
      component: () => import("@/views/PhoneGuide/ShowPhoneGuide.vue"),
      meta: {
        pageTitle: "إظهار دليل الهاتف",
        breadcrumb: [
          {
            text: "إظهار دليل الهاتف",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    // *===============================================---*
    // *--------- Product --------------------------------------------*
    // *===============================================---*
    {
      path: "/Used/Product/Add",
      name: "AddProduct-used-type",
      component: () => import("@/views/Product/ChooseTypeNavigation.vue"),
      meta: {
        requiresAuth: true,
        per: "",
      },
    },

    {
      path: "/Product/Add",
      name: "AddProduct-type",
      component: () => import("@/views/MultiProduct/ChooseTypeNavigation.vue"),
      meta: {
        requiresAuth: true,
        per: "",
      },
    },


    {
      path: "/Used/Product/:category_id/Add/:type",
      name: "AddProduct",
      component: () => import("@/views/Product/AddProductSelectData.vue"),
      meta: {
        pageTitle: "أضف منتج",
        breadcrumb: [
          {
            text: "أضف منتج",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/Product/Index",
      name: "ProductIndex",
      component: () => import("@/views/Product/ProductIndex.vue"),
      meta: {
        pageTitle: "فهرس المنتجات ",
        breadcrumb: [
          {
            text: "فهرس المنتجات ",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/Used/Product/Card/Index",
      name: "ProductCardIndex",
      component: () => import("@/views/Product/ProductEcommIndex.vue"),
      meta: {
        contentRenderer: "sidebar-left-detached",
        contentClass: "ecommerce-application",
        pageTitle: "فهرس بطاقة المنتجات ",
        breadcrumb: [
          {
            text: "ECommerce",
          },
          {
            text: "فهرس بطاقة المنتجات ",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/Used/ShowProduct/:id",
      name: "ShowProduct",
      component: () => import("@/views/Product/ShowProduct.vue"),
      meta: {
        pageTitle: "عرض المنتج",
        breadcrumb: [
          {
            text: "عرض المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/Used/ShowProduct/create/variant/:id",
      name: "Create-Variant",
      component: () => import("@/views/Product/Variant/StoreVariant.vue"),
      meta: {
        pageTitle: "Create Variant",
        breadcrumb: [
          {
            text: "Create Variant",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/UpdateProductMainData/:id",
      name: "UpdateProduct",
      component: () => import("@/views/Product/UpdateProductMainData.vue"),
      meta: {
        pageTitle: "تحديث بيانات المنتج الرئيسية",
        breadcrumb: [
          {
            text: "تحديث بيانات المنتج الرئيسية",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/Product/UpdateTags/:id",
      name: "UpdateProductTags",
      component: () => import("@/views/Product/UpdateTags.vue"),
      meta: {
        pageTitle: "تحديث العلامات",
        breadcrumb: [
          {
            text: "تحديث العلامات",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/Product/UpdateTranslation/:id",
      name: "UpdateProductTranslation",
      props: true,
      component: () => import("@/views/Product/UpdateItemTranslation"),
      meta: {
        pageTitle: "Update Translation",
        breadcrumb: [
          {
            text: "Update Translation",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/Product/addNewTranslation/:id",
      name: "addNewProductTranslation",
      props: true,
      component: () => import("@/views/Product/addNewTranslation"),
      meta: {
        pageTitle: "أضف ترجمة جديدة",
        breadcrumb: [
          {
            text: "أضف ترجمة جديدة",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/Product/AddNewImage/:id",
      name: "AddNewProductImage",
      props: true,
      component: () => import("@/views/Product/AddNewImage"),
      meta: {
        pageTitle: "إضافة صورة",
        breadcrumb: [
          {
            text: "إضافة صورة",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "used_product",
      },
    },
    {
      path: "/Used/ShowProduct/Variant/:id",
      name: "ShowProductVariant",
      props: true,
      component: () => import("@/views/Product/ShowEAV"),
      meta: {
        pageTitle: "المنتج",
        breadcrumb: [
          {
            text: "المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/Used/ShowProduct/variant/show/:id",
      name: "Show-Variant",
      props: true,
      component: () => import("@/views/Product/Variant/ShowVariant"),
      meta: {
        pageTitle: "المنتج",
        breadcrumb: [
          {
            text: "المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/Used/ShowProduct/update/variant/:id",
      name: "UpdateProductVariant",
      props: true,
      component: () => import("@/views/Product/Variant/UpdateVariant"),
      meta: {
        pageTitle: " تحديث المنتج",
        breadcrumb: [
          {
            text: " تحديث المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      
      },
    },
    {
      path: "/Used/ShowProduct/variant/add/image/:id",
      name: "AddUsedVariantImage",
      props: true,
      component: () => import("@/views/Product/Variant/AddNewImage"),
      meta: {
        pageTitle: "أضف صورة",
        breadcrumb: [
          {
            text: "أضف صورة",
            active: true,
          },
        ],
        requiresAuth: true,
      
      },
    },

    {
      path: "/New/Product/Add",
      name: "AddNewProduct-type",
      component: () => import("@/views/NewProduct/ChooseTypeNavigation.vue"),
      meta: {
        pageTitle: "أضف منتج",
        breadcrumb: [
          {
            text: "أضف منتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/New/Product/:category_id/Add/:type",
      name: "AddNewProduct",
      component: () => import("@/views/NewProduct/AddProductWithOffer.vue"),
      meta: {
        pageTitle: "أضف منتج جديد",
        breadcrumb: [
          {
            text: "أضف منتج جديد",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },

    

    {
      path: "/:product_type/Product/Add/:category_id/:type",
      name: "AddNewCatigoryProduct",
      component: () => import("@/views/MultiProduct/OtherCategories/All/AddProductWithOffer.vue"),
      meta: {
        pageTitle: "أضف منتج جديد",
        breadcrumb: [
          {
            text: "أضف منتج جديد",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "portal_new_mobile",
      },
    },


  
    {
      path: "/New/Product/Card/Index",
      name: "NewProductCardIndex",
      component: () => import("@/views/NewProduct/ProductEcommIndex.vue"),
      meta: {
        contentRenderer: "sidebar-left-detached",
        contentClass: "ecommerce-application",
        pageTitle: "New Products Card Index",
        breadcrumb: [
          {
            text: "ECommerce",
          },
          {
            text: "New Products Card Index",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/New/ShowProduct/:id",
      name: "NewShowProduct",
      component: () => import("@/views/NewProduct/ShowProduct.vue"),
      meta: {
        pageTitle: "عرض منتج جديد",
        breadcrumb: [
          {
            text: "عرض منتج جديد",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/New/ShowProduct/create/variant/:id",
      name: "Create-Variant-New",
      component: () => import("@/views/NewProduct/Variant/StoreVariant.vue"),
      meta: {
        pageTitle: "Create New Variant",
        breadcrumb: [
          {
            text: "Create New Variant",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/UpdateProductMainData/:id",
      name: "UpdateNewProduct",
      component: () => import("@/views/NewProduct/UpdateProductMainData.vue"),
      meta: {
        pageTitle: "Update New Product Main Data",
        breadcrumb: [
          {
            text: "Update New Product Main Data",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/Product/UpdateTags/:id",
      name: "UpdateNewProductTags",
      component: () => import("@/views/NewProduct/UpdateTags.vue"),
      meta: {
        pageTitle: "Update Tags",
        breadcrumb: [
          {
            text: "Update Tags",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/Product/UpdateTranslation/:id",
      name: "UpdateNewProductTranslation",
      props: true,
      component: () => import("@/views/NewProduct/UpdateItemTranslation"),
      meta: {
        pageTitle: "Update Translation",
        breadcrumb: [
          {
            text: "Update Translation",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/Product/addNewTranslation/:id",
      name: "addProductTranslation",
      props: true,
      component: () => import("@/views/Product/addNewTranslation"),
      meta: {
        pageTitle: "Add New Translation",
        breadcrumb: [
          {
            text: "Add New Translation",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/Product/AddNewImage/:id",
      name: "AddNewNewProductImage",
      props: true,
      component: () => import("@/views/NewProduct/AddNewImage"),
      meta: {
        pageTitle: "أضف صورة",
        breadcrumb: [
          {
            text: "أضف صورة",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/New/ShowProduct/Variant/:id",
      name: "ShowNewProductVariant",
      props: true,
      component: () => import("@/views/NewProduct/ShowEAV"),
      meta: {
        pageTitle: "المنتج",
        breadcrumb: [
          {
            text: "المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/New/ShowProduct/variant/show/:id",
      name: "Show-Variant-NewProduct",
      props: true,
      component: () => import("@/views/NewProduct/Variant/ShowVariant"),
      meta: {
        pageTitle: "المنتج",
        breadcrumb: [
          {
            text: "المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/New/ShowProduct/update/variant/:id",
      name: "UpdateNewProductVariant",
      props: true,
      component: () => import("@/views/NewProduct/Variant/UpdateVariant"),
      meta: {
        pageTitle: " تحديث المنتج",
        breadcrumb: [
          {
            text: "  تحديث المنتج",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/New/ShowProduct/variant/add/image/:id",
      name: "AddNewVariantImage",
      props: true,
      component: () => import("@/views/NewProduct/Variant/AddNewImage"),
      meta: {
        pageTitle: "أضف صورة",
        breadcrumb: [
          {
            text: "أضف صورة",
            active: true,
          },
        ],
        requiresAuth: true,
        per: "",
      },
    },
    {
      path: "/variants/:status",
      name: "VariantsIndex",
      props: true,
      component: () => import("@/views/Product/Variant/IndexByStatus"),
      meta: {
        pageTitle: "المتغيرات",
        breadcrumb: [
          {
            text: "المتغيرات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/Products/Top",
      name: "TopProducts",
      props: true,
      component: () => import("@/views/Analysis Reports/TopVariants.vue"),
      meta: {
        pageTitle: "أفضل المنتجات ",
        breadcrumb: [
          {
            text: "أفضل المنتجات ",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    // *===============================================---*
    // *--------- Orders  --------------------------------------------*
    // *===============================================---*
    {
      path: "/orders/invoice/:id",
      name: "invoice",
      props: true,
      component: () => import("@/views/Orders/invoice.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders/show/:id",
      name: "Orders-Show",
      props: true,
      component: () => import("@/views/Orders/ShowOrder.vue"),
      meta: {
        pageTitle: "إظهار الطلب",
        breadcrumb: [
          {
            text: "إظهار الطلب",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/orders/OrderIndex/",
      name: "OrderIndex",
      component: () => import("@/views/Orders/OrderIndex.vue"),
      meta: {
        pageTitle: "الطلبات",
        breadcrumb: [
          {
            text: "الطلبات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/orders/Update/Status/:id",
      name: "UpdateOrderStatus",
      props: true,
      component: () => import("@/views/Orders/ChangeOrderStatus.vue"),
      meta: {
        pageTitle: "تحديث حالة الطلب",
        breadcrumb: [
          {
            text: "تحديث حالة الطلب",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/orders/pending-amount/",
      name: "OrderPendingAmount",
      component: () => import("@/views/Orders/GetPendingAmountOrders.vue"),
      meta: {
        pageTitle: "رصيد قيد التحصيل",
        breadcrumb: [
          {
            text: "رصيد قيد التحصيل",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/orders/:status",
      name: "OrderIndexByStatus",
      component: () => import("@/views/Orders/GetOrdersByStatus.vue"),
      meta: {
        pageTitle: "الطلبات",
        breadcrumb: [
          {
            text: "الطلبات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    // *===============================================---*
    // *--------- Transactions ---- ---------------------------------------*
    // *===============================================---*
    {
      path: "/transaction/index",
      name: "Transactions-Index",
      component: () => import("@/views/Transactions/index.vue"),
      meta: {
        pageTitle: "المعاملات",
        breadcrumb: [
          {
            text: "المعاملات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/transaction/show/:id",
      name: "Transactions-Show",
      props: true,
      component: () => import("@/views/Transactions/ShowPayout.vue"),
      meta: {
        pageTitle: "عرض المدفوعات",
        breadcrumb: [
          {
            text: "عرض المدفوعات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/transaction/update/:id",
      name: "Transactions-Update",
      props: true,
      component: () => import("@/views/Transactions/update.vue"),
      meta: {
        pageTitle: "Update Payout",
        breadcrumb: [
          {
            text: "Update Payout",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/transaction/add",
      name: "Transactions-Add",
      props: true,
      component: () => import("@/views/Transactions/storePayouts.vue"),
      meta: {
        pageTitle: " التعاملات المالية",
        breadcrumb: [
          {
            text: "التعاملات المالية",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/transaction/received-amount",
      name: "Transactions-Received-Index",
      component: () =>
        import("@/views/Transactions/GetReceivedAmountPayouts.vue"),
      meta: {
        pageTitle: "رصيد سابق",
        breadcrumb: [
          {
            text: "رصيد سابق",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    // *===============================================---*
    // *--------- Vendor -------------------------------------------*
    // *===============================================---*
    {
      path: "/vendor/show/",
      name: "Vendor-Show",
      component: () => import("@/views/Vendor/ShowVendor.vue"),
      meta: {
        pageTitle: "الملف الشخصي ",
        breadcrumb: [
          {
            text: "الملف الشخصي  ",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/vendor/update",
      name: "Vendor-Update",
      component: () => import("@/views/Vendor/UpdateVendor.vue"),
      meta: {
        pageTitle: "تحديث بيانات البائع",
        breadcrumb: [
          {
            text: "تحديث بيانات البائع",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/vendor/password/change",
      name: "Vendor-Change-Password",
      component: () => import("@/views/Vendor/VendorChangePassword.vue"),
      meta: {
        pageTitle: "تغيير كلمة المرور البائع",
        breadcrumb: [
          {
            text: "تغيير كلمة المرور البائع",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/vendor/add/mobile",
      name: "Vendor-Add-Mobile",
      component: () => import("@/views/Vendor/AddNewMobile.vue"),
      meta: {
        pageTitle: "Add New Mobiles",
        breadcrumb: [
          {
            text: "Add New Mobiles",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    // *===============================================---*
    // *--------- reports -------------------------------------------*
    // *===============================================---*
    {
      path: "/diagnostic/reports",
      name: "Diagnostic-Reports",
      component: () => import("@/views/DTReport/index.vue"),
      meta: {
        pageTitle: "تقارير أداة التشخيص",
        breadcrumb: [
          {
            text: "تقارير أداة التشخيص",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/diagnostic/reports/show/:id",
      props: true,
      name: "Diagnostic-Reports-Show",
      component: () => import("@/views/DTReport/Show.vue"),
      meta: {
        pageTitle: "إظهار تقارير أداة التشخيص",
        breadcrumb: [
          {
            text: "إظهار تقارير أداة التشخيص",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    // *===============================================---*
    // *--------- Refund ---- ---------------------------------------*
    // *===============================================---*
    {
      path: "/orders/refund/index",
      name: "Refund-Index",
      component: () => import("@/views/Refund/Index.vue"),
      meta: {
        pageTitle: "المرتجعات",
        breadcrumb: [
          {
            text: "المرتجعات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/orders/refund/index/:status",
      name: "RefundIndexByStatus",
      component: () => import("@/views/Refund/GetRefundsByStatus.vue"),
      meta: {
        pageTitle: "المرتجعات",
        breadcrumb: [
          {
            text: "المرتجعات",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/orders/refund/show/:id",
      name: "Refund-Show",
      props: true,
      component: () => import("@/views/Refund/Show.vue"),
      meta: {
        pageTitle: "المرتجع",
        breadcrumb: [
          {
            text: "المرتجع",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    // *===============================================---*
    // *--------- Offers -------------------------------------------*
    // *===============================================---*
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        pageTitle: 'التنبيهات',
        breadcrumb: [
          {
            text: 'التنبيهات',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'notifications',
      },
    },
    {
      path: "/offers",
      name: "Offers",
      component: () => import("@/views/offers/index.vue"),
      meta: {
        pageTitle: "العروض",
        breadcrumb: [
          {
            text: "عروض",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "الصفحة الرئيسية",
        breadcrumb: [
          {
            text: "الصفحة الرئيسية",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
const openRoutes = ["login"];

router.beforeEach((to, from, next) => {
  // check if isAuthenticated equal true
  // this mean that the token in the storage not null
  if (store.getters.isAuthenticated) {
    // const vendorData = JSON.parse(Vue.CryptoJS.AES.decrypt(localStorage.getItem("userData"),"EncryptDataMobileMasr").toString(Vue.CryptoJS.enc.Utf8));

    // get and validate the AuthenticatedUser state from getter state
    const checkAuthenticatedUser = store.getters.checkAuthenticatedUser;
    if (checkAuthenticatedUser == null) {

      // check if there is stored authPermissions in the locale storage
      if (localStorage.getItem('userData') != null) {

        // if authenticated user in the state equal to null 
        // then get the authenticated permission from the storage
        const localStorageVendorData = JSON.parse(Vue.CryptoJS.AES.decrypt(localStorage.getItem("userData"), "EncryptDataMobileMasr").toString(Vue.CryptoJS.enc.Utf8));


        store.commit('setAuthVendor', localStorageVendorData)
        store.dispatch('getAuthenticatedVendor')



      } else {

        store.dispatch('logout')
        return;
      }

    }

    const vendorData = store.state.vendorData

    // const requiresPermission = to.meta.hasOwnProperty('per');

    // if (requiresPermission) {
    //   if (to.meta.per == '' && vendorData.is_sell_new == false) {
    //     next('/');
    //   }
    //   if (to.meta.per == 'used_product' && vendorData.is_sell_used == false) {
    //     next('/');
    //   }

    // }



    if (store.getters.isAuthenticated) {
      if (to.name != 'login') {
        next()
      } else {
        next('/')
      }
    }

  }
  else {

    if (openRoutes.includes(to.name)) {
      next();
    } else if ($cookies.get("token") != null) {
      next();
    } else {
      next("/login");
    }
  }

  // Main and final Routing
  // router.beforeEach((to, from, next) => {
  //     const perm = localStorage.getItem('per')
  //     if (openRoutes.includes(to.name)) {
  //         next()
  //     } else if (store.getters.isAuthenticated) {
  //         if (to.name != 'Home' && to.name != 'login' && to.name != '404') {
  //             if (to.meta.access == perm.includes(to.meta.per)) {
  //                 next()
  //             } else {
  //                 next('/')
  //             }
  //         } else {
  //             next()
  //         }
  //     } else {
  //         next('/login')
  //     }
});

export default router;
